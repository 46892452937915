import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import SendBird, { SendBirdInstance } from 'sendbird'
import { ChannelUrlKey } from './constants'
import {
  clearPersistKey,
  getPersistedObjectState,
  setPersistedObjectState,
  usePersistedState
} from '@commonstock/common/src/utils/usePersistedState'
import config from '../../config'
import { useAuth } from '../auth/AuthContext'
import { Authenticated } from '../auth/constants'
import { AuthInfo, useGetAuthInfoAction } from '@commonstock/common/src/api/chat'
import { SendbirdAuthTokensKey } from './constants'
import { useRouter } from 'next/router'
import { Routes } from 'src/scopes/nav/constants'

export const sendbird = new SendBird({ appId: config.sendBirdAppId })

// @ts-ignore
if (typeof window !== 'undefined') window._sendbird = sendbird

type ChatValue = {
  channelUrl: string | null
  isConnected: boolean
  sendbird: SendBirdInstance
  setChannelUrl: (channelUrl: string | null, redirect?: boolean) => void
}

const ChatContext = createContext<ChatValue>({
  channelUrl: null,
  isConnected: false,
  sendbird,
  setChannelUrl: () => {}
})
ChatContext.displayName = 'ChatContext'

const ChatProvider = ({ children }: { children: ReactNode }) => {
  const { authenticated } = useAuth()
  const router = useRouter()
  const [channelUrl, _setChannelUrl] = usePersistedState<string | null>(ChannelUrlKey, null)

  const [isConnected, setIsConnected] = useState(false)
  const getAuthInfo = useGetAuthInfoAction()

  const connectToSendBird = useCallback((userId, token) => {
    sendbird.connect(userId, token, (_user, error) => {
      if (error) {
        console.warn('## sendbird connect err:', error)
        // @TODO exponential backoff
        setInterval(() => connectToSendBird(userId, token), 5000)
      } else {
        setIsConnected(true)
      }
    })
  }, [])

  const setChannelUrl = useCallback(
    (_channelUrl: string | null, redirect?: boolean) => {
      const routeChannelUrl = _channelUrl?.startsWith('sendbird_group_channel_')
        ? _channelUrl.replace('sendbird_group_channel_', '')
        : _channelUrl
      const formattedChannelUrl =
        _channelUrl === null
          ? _channelUrl
          : _channelUrl.startsWith('sendbird_group_channel_')
          ? _channelUrl
          : 'sendbird_group_channel_' + _channelUrl
      if (router.pathname === Routes.Messages() || redirect) {
        router.push(Routes.Messages(), Routes.Messages(routeChannelUrl), { shallow: true })
      }
      _setChannelUrl(formattedChannelUrl)
    },
    [_setChannelUrl, router]
  )

  useEffect(() => {
    if (authenticated !== Authenticated.Yes) return
    let active = true
    async function authenticate() {
      if (!active) return
      try {
        let tokens: AuthInfo | null = await getPersistedObjectState(SendbirdAuthTokensKey)
        if (!tokens || tokens.expires_at < new Date().getTime()) {
          let data = await getAuthInfo()
          if (data.success) {
            setPersistedObjectState(SendbirdAuthTokensKey, data.success.payload)
            tokens = data.success.payload
          }
        }
        if (tokens) connectToSendBird(String(tokens.user_uuid), tokens.sendbird_auth_token)
        else throw new Error(`### Cant get AuthInfo`)
      } catch (err) {
        console.error('## failed to auth chat', err)
        // retry every 5s to work around backend timing issues
        setTimeout(() => {
          clearPersistKey(SendbirdAuthTokensKey)
          authenticate()
        }, 5000)
      }
    }

    authenticate()
    return () => {
      active = false
    }
  }, [getAuthInfo, authenticated, connectToSendBird])
  useEffect(() => {
    if (typeof router.query.channel === 'string') {
      setChannelUrl(router.query.channel)
    }
    return () => {}
  }, [_setChannelUrl, channelUrl, router, setChannelUrl])

  const value: ChatValue = useMemo(
    () => ({
      channelUrl,
      isConnected,
      sendbird,
      setChannelUrl
    }),
    [channelUrl, isConnected, setChannelUrl]
  )

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}

const useChat = () => {
  const context = useContext(ChatContext)
  return context
}

export { ChatProvider, useChat }
